import request from '@/util/request';

/**
 * 登录
 * @param {phone,uuid,password} data
 */
export function departmentAll(params) {
    return request({
        url: `/api/v1/account/department/all`,
        method: 'GET',
        params,
    })
}

export function departmentEmployeeUnion(params) {
    return request({
        url: `/api/v1/account/department/employee/union`,
        method: 'GET',
        params,
    })
}

export function departmentEmployeeList(params) {
    return request({
        url: `/api/v1/account/department/employee/list`,
        method: 'GET',
        params,
    })
}

export function departmentBindEmployee(params) {
    return request({
        url: `/api/v1/account/department/bindEmployee`,
        method: 'GET',
        params,
    })
}

export function departmentList() {
  return request({
    url: '/api/v1/account/department/list',
    method: 'get',
  })
}

export function departmentDeviceRely() {
  return request({
    url: '/api/v1/account/department/deviceRely',
    method: 'get',
  })
}

export function departmentCreate(data) {
  return request({
    url: '/api/v1/account/department/create',
    method: 'post',
    data
  })
}



