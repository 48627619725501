<template>
  <div class="device-index">
    <div class="device-department" v-show="!deviceShow && !deviceDrinkShow">
      <div class="device-group">设备组</div>
      <tree-depart @node-click="handleNodeClick" ref="group"></tree-depart>
    </div>
    <div class="device-list" v-show="!deviceShow && !deviceDrinkShow">
      <el-form label-width="auto" :inline="true" size="mini">
        <el-col :span="6">
          <el-form-item label="地理位置:">
            <el-cascader
              class="device-input-filter"
              size="mini"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
              @change="handleAreaChange">
            </el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备状态:" class="mini">
            <el-select placeholder="请选择设备状态" class="device-input-filter" v-model="pagination.status"
                       @change="loadDeviceSearch">
              <el-option v-for="item in deviceStatusOption"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="text-right">
          <el-form-item label="联系人:">
            <el-input placeholder="设备联系人名称" class="device-input-filter clear-right-padding"
                      clearable
                      v-model="pagination.dutyName"
                      @change="loadDeviceSearch"
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>

        </el-col>
        <el-col :span="6" class="text-right">
          <el-form-item label="条件搜索:">
            <el-input placeholder="设备名称/设备编号" class="device-input-filter clear-right-padding"
                      clearable
                      v-model="pagination.deviceInfo"
                      @change="loadDeviceSearch"
                      prefix-icon="el-icon-search"></el-input>
          </el-form-item>

        </el-col>
      </el-form>
      <el-table
        :data="devices">
        <empty slot="empty"></empty>
        <el-table-column label="设备编号" align="center" prop="deviceUuid" min-width="100"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="deviceName" min-width="120"></el-table-column>
        <el-table-column label="设备状态" align="center">
          <template slot-scope="scope">
            <div class="user-info">
                            <span :class="[{ offline: scope.row.status ==1 }, { normal: scope.row.status ==2 },
                                    { warning: scope.row.status ==3 },
                                    { fault: scope.row.status ==4 }]">
                                   <span class="circle"></span> {{scope.row.status | filterName}}
                                </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="设备联系人" align="center" prop="dutyUserName"></el-table-column>
        <el-table-column label="联系电话" align="center" prop="dutyUserPhone"></el-table-column>

        <el-table-column label="已有支付" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.wechatPay ===0 && scope.row.aliPay ===0">无配置</span>
            <i class="iconfont icon-zhifubao" style="color: #108ee9;" v-if="scope.row.aliPay ===1"></i>
            <i class="iconfont icon-weixin" style="color: #1AAD19;" v-if="scope.row.wechatPay ===1"></i>
          </template>
        </el-table-column>
        <el-table-column label="设备位置" align="center" min-width="180">
          <template slot-scope="scope">
            <div class="user-info" v-if="scope.row.province.length >0">
              {{scope.row.province}}/{{scope.row.city}} {{scope.row.address}}
            </div>
            <div class="user-info" v-if="scope.row.province.length ==0">
              <span>暂未设置地理位置</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" prop="name" min-width="80">
          <template slot-scope="scope">
            <p class="device-detail " @click="showDeviceInfo(scope.row,scope)">
              <i class="iconfont iconyichang" title="设备信息"></i>
              设备信息
            </p>
            <p class="device-edit " @click="showDeviceDrink(scope.row)">
              <i
                 class="iconfont iconyinpin" title="饮品配置"></i>
              设备饮品
            </p>

          </template>

        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        class="pagination"
        :current-page="pagination.curPage"
        :page-size="pagination.limit"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>

    </div>

    <device-info @close="handleDeviceShow" v-show="deviceShow" ref="deviceInfo"></device-info>
    <device-drink @close="handleDeviceShow" v-show="deviceDrinkShow" ref="deviceDrink"></device-drink>

  </div>
</template>

<script>
  import {provinceAndCityDataPlus, CodeToText} from 'element-china-area-data'
  import {deviceUnion, deviceList, devicePositionUpdate} from '@/api/equipment/device'

  import TreeDepart from '@/components/TreeDepart/index.vue'

  export default {
    name: '',
    components: {
      TreeDepart,
      DeviceInfo: function (resolve) {
        require(["./device_info.vue"], resolve);
      },
      DeviceDrink: function (resolve) {
        require(["./device_drink.vue"], resolve);
      },
    },
    mounted: function () {
      this.loadDevice()
    },
    data: function () {
      return {
        options: provinceAndCityDataPlus,
        devices: [],
        deviceStatusOption: [
          {label: '所有设备', value: 0},
          {label: '离线', value: 1},
          {label: '在线', value: 2},
          {label: '待维护', value: 3},
          {label: '待维修', value: 4},
        ],
        pagination: {
          deviceInfo: '',
          dutyName: '',
          status: 0,
          province: '',
          city: '',
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
        device: {
          deviceInfo: '',
          dutyName: '',
          province: '',
          city: '',
          address: '',
          lat: '',
          lng: '',
          block: false,

        },
        groups: [],
        deviceShow: false,
        deviceDrinkShow: false,
        deviceIndex: 0,
      }
    },
    filters: {
      filterName(status) {
        switch (status) {
          case 1:
            return '离线'
          case 2:
            return '正常'
          case 3:
            return '待维护'
          case 4:
            return '待维修'
        }
      },
    },
    methods: {
      handleNodeClick(node) {
        if (this.pagination.departmentUuid === node.uuid) {
          return
        }
        this.pagination.departmentUuid = node.uuid
        this.loadDeviceSearch()
      },

      handleDeviceShow() {
        this.deviceShow = false
        this.deviceDrinkShow = false
        this.handleCurrentChange(this.pagination.curPage)
      },

      showDeviceInfo(device) {
        let groups = this.$refs.group.getGroups()
        this.deviceShow = true
        this.$nextTick(function () {
          this.$refs.deviceInfo.resetInfo(device, groups)
        })
      },

      showDeviceDrink(device) {
        console.log("enter")
        this.deviceDrinkShow = true
        this.$nextTick(function () {
          this.$refs.deviceDrink.resetDevice(device)
        })
      },

      handleCommand(row, index, command) {
        switch (command) {
          case 'delivery.devices.drink':
            this.$router.push({name: command, params: row})
            break;
          case 'delivery.device.geo':
            this.loadMap(row, index)
            break;
        }
      },

      handleDelete(row, index) {
      },
      handleAreaChange(value) {
        this.pagination.city = ''
        if (!value[0].length) {
          this.pagination.province = ''
          this.pagination.city = ''
        } else {
          this.pagination.province = CodeToText[value[0]]
          if (value[1].length) {
            let city = CodeToText[value[1]]
            if (city !== '全部') {
              this.pagination.city = city
            }
          }
        }
        this.loadDeviceSearch()
      },

      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        deviceList(this.pagination).then(res => {
          this.devices = res.data || []
        })
      },
      loadDevice() {
        let vue = this
        deviceUnion(this.pagination).then(res => {
          vue.devices = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      loadDeviceSearch() {
        let vue = this
        this.pagination.curPage = 1
        this.pagination.start = 0
        deviceUnion(this.pagination).then(res => {
          vue.devices = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },

      loadMap(device, index) {
        let vue = this
        vue.deviceIndex = index
        this.device.block = device.lock === 1 || device.lock === true
        for (let item in device) {
          if (this.device.hasOwnProperty(item)) {
            this.device[item] = device[item]
          }
        }
        this.updateDeviceVisible = true

      },

      handleDeviceUpdate() {
        if (this.device.name === ``) {
          this.$message.error('请完善设备名称')
          return
        }

        if (this.device.province === ``) {
          this.$message.error('请设定设备所在位置')
          return
        }

        let vue = this
        this.device.lat = this.device.lat + ''
        this.device.lng = this.device.lng + ''
        this.device.lock = this.device.block ? 1 : 0

        devicePositionUpdate(this.device).then(() => {
          let device = vue.devices[vue.deviceIndex]
          device.name = vue.device.name
          device.province = vue.device.province
          device.city = vue.device.city
          device.address = vue.device.address
          vue.updateDeviceVisible = false
          vue.devices[vue.deviceIndex].lock = vue.device.lock
          console.log(vue.devices[vue.deviceIndex].block)
          vue.$message.success('更新成功')
        })
      },
    },
  }
</script>

<style lang="scss">
  .device-index {
    display: flex;

    .device-department {
      width: 180px;
      padding-right: $padding;
      border-right: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      .device-group {
        width: 100%;
        text-align: center;
        @include set_font_color($_theme_color, $--font-color-dark);
        @include set_bg_color(#eef8ff, #454954);
        padding: 4px 0;
        border-radius: $border-radius;
        margin-bottom: $padding;
      }
    }

    .device-list {
      flex: 1;
      padding-left: $padding;
      @media (max-width: 1366px) {
        .el-form-item__label {
          width: 64px;
        }
      }

      @media (max-width: 1442px) {
        .el-form-item__label {
          width: 72px;
        }
      }

      .el-form-item__content {
        width: 150px;

        .clear-right-padding {
          .el-input__inner {
            padding-right: 8px;
          }

        }
      }

      .device-info {
        .device-info-img {
        }

        .device-info-detail {

          p {
            margin-bottom: $padding;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .text-right {
        text-align: right;
      }

      .el-table {
        thead {
          tr {
            th {
              border-bottom: 1px solid;
              @include set_border_color($--border-color, $--border-color-dark);
            }
          }
        }

        .cell {
          font-size: $font_little;
        }

      }

      .user-info {
        p:first-child {
          margin-bottom: $padding;
        }
      }

      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
        @include set_bg_color($background-color-light, $background-color-dark);
      }

      .pagination {
        text-align: right;
        margin-top: $padding-8;

        .el-pagination__total {
          float: left;
        }
      }

      .device-edit, .device-detail {
        cursor: pointer;
        display: inline-block;
        margin-right: $padding-8;
      }

      .device-edit:hover, .device-detail:hover {
        font-weight: 500;
      }

      .device-edit {
        color: $btn_color;
        cursor: pointer;
      }

      .device-detail {
        color: $_theme_color;
        cursor: pointer;
      }

      .circle {
        border-radius: $font_little;
        width: $font_little;
        height: $font_little;
        display: inline-block;
        margin-right: 5px;
      }

      .offline {
        color: #5B8FF9;

        .circle {
          background-color: #5B8FF9;
        }
      }

      .normal {
        color: #5AD8A6;

        .circle {
          background-color: #5AD8A6;
        }
      }

      .warning {
        color: #F6BD16;

        .circle {
          background-color: #F6BD16;
        }
      }

      .fault {
        color: #E8684A;

        .circle {
          background-color: #E8684A;
        }
      }
    }
  }
</style>
