<template>
  <div class="drink-sync">
    <div class="drink-store clear-fix">
      <div class="drink-item">
        <div class="drink-content " @click="handleCreateDrink()">
          <img :src="add"/>
          <p> 新增配方</p>
        </div>
      </div>
      <div class="drink-item" v-for="(drinkSync,index) in drinkSyncs">
        <div class="drink-content" @click="handleEditDrink(drinkSync)">
          <img :src="imgs[drinkSync.imgIndex]"/>
          <p> {{drinkSync.name}}</p>
        </div>
      </div>
    </div>

    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>
</template>
<script>
  import {drinkSyncUnion, drinkSyncList, drinkSyncParam} from '@/api/equipment/drink'

  export default {
    name: '',
    components: {},
    mounted: function () {
      for (let i = 1; i < 16; i++) {
        this.imgs.push(require('@/assets/img/icon/scenery-' + i + '.png'))
      }
    },
    activated: function () {
      this.load()
    },
    data: function () {
      return {
        radio1: '0',
        add: require('@/assets/img/icon/add.png'),
        imgs: [],

        drinkSyncs: [],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 23,
          total: 0,
        },
      }
    },
    methods: {
      load() {
        let vue = this
        drinkSyncUnion(this.pagination).then(res => {
          vue.drinkSyncs = res.data.list || []
          vue.pagination.total = parseInt(res.data.total)
        })
      },
      handleEditDrink(drink) {
        if (drink.isSuccess === 0) {

          this.$router.push({name: 'equipment.drink.create', params: {drink: drink}})
          return
        }

        drinkSyncParam({uuid: drink.uuid}).then(res => {
          let drinks = res.data
          if (!drinks) {
            this.$message.success('饮品暂无配置')
            return;
          }
          this.$router.push({
            name: 'equipment.drink.edit',
            params: {uuid: drink.uuid, drink: drinks, productUuid: drink.productUuid}
          })
        })
      },
      handleCreateDrink() {
        this.$router.push({name: 'equipment.drink.create', params: {isCreate: true}})
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        drinkSyncList(this.pagination).then(res => {
          this.devices = res.data || []
        })
      },
    },
  }
</script>

<style lang="scss">
  .drink-sync {
    .drink-store {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start;
      min-height: 500px;

      .drink-item {
        flex: 0 0 12.5%;

        .drink-content {
          padding: $padding-8;
          margin-bottom: $padding;
          border-radius: $border-radius;
          text-align: center;

          i {
            font-size: 88px;
          }

          img {
            max-width: 94px;
          }

          p {
            font-size: 12px;
          }
        }

        .drink-content:hover {
          cursor: pointer;
          @include set_bg_color(#edf0f7, #3d4042);
        }

      }
    }
  }

</style>
