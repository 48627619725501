<template>
  <el-tree
    :data="groups"
    @node-click="nodeClick"
    node-key="uuid"
    default-expand-all
    :expand-on-click-node="false">
    <span class="custom-tree-node" slot-scope="{ node, data }">
        <span :class="{active: data.uuid ===activeUuid}">{{ data.name }}</span>

      </span>
  </el-tree>
</template>

<script>
  import {departmentDeviceRely} from '@/api/account/department'

  export default {
    name: '',
    components: {},
    mounted: function () {
      this.loadDepartment()
    },
    computed: {},
    data: function () {
      return {
        groups: [],
        activeUuid: ''
      }
    },
    methods: {
      loadDepartment() {
        departmentDeviceRely().then(res => {
          let groups = res.data || []
          this.groups = [{uuid:``, name:"全部分组"}].concat(groups)
        })
      },
      nodeClick(data) {
        this.activeUuid = data.uuid
        this.$emit(`node-click`, data)
      },
      getGroups() {
        return this.groups
      }
    },

  }
</script>

<style lang="scss">
  .custom-tree-node {
    width: 100%;

    .active {
      color: $_theme_color;
    }

  }


  .group-operate {
    .el-button {
      display: block;
      margin-left: 10px;
    }
  }
</style>

