<template>

  <div class="device-drink">
    <div class="device-chart-filter">
      <el-menu
        mode="horizontal"
        :default-active="activeName"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="item in menus"
          :key="item.name"
          :index="item.name"
        >{{item.label}}
        </el-menu-item>
      </el-menu>
    </div>
    <device class="device-container" v-show="activeName == 'device'"></device>
    <drink class="device-container" v-show="activeName == 'drink'"></drink>
    <price class="device-container" v-show="activeName == 'price'"></price>
  </div>
</template>

<script>
  import Device from './Device/index.vue'
  import Drink from './Drink/index.vue'
  import Price from './Price/index.vue'

  export default {
    name: '',
    components: {
      Device,
      Drink,
      Price
    },
    mounted: function () {
    },
    data: function () {
      const menus = [
        {name: 'device', label: '设备列表'},
        {name: 'drink', label: '饮品同步'},
        {name: 'price', label: '批量定价'}
      ]
      return {
        activeName: 'device',
        menus
      }
    },
    methods: {
      handleSelect(name) {
        this.activeName = name
      }
    },
  }
</script>

<style lang="scss">
  .device-drink {
    padding-top: $padding-8 !important;

    .device-chart-filter {
      margin-bottom: $padding-inner;

    }

    .device-container {
      min-height: 552px;
      border-radius: $border-radius;
      @media (max-height: 768px) {
        min-height: 518px;
      }
    }
  }
</style>
