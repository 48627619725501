/**
 * Created by fworld on 2019/9/25.
 */
import request from '@/util/request'

export const deviceUnion = (params) => {
    return request({
        'url': '/api/v1/marketing/device/union',
        'method': 'GET',
        params
    })
}

export const deviceList = (params) => {
    return request({
        'url': '/api/v1/marketing/device/list',
        'method': 'GET',
        params
    })
}


export const deviceUpdate = (data) => {
    return request({
        'url': '/api/v1/marketing/device/update',
        'method': 'POST',
        data
    })
}

export const deviceDrinkBatchUpdate = (data) => {
    return request({
        'url': '/api/v1/marketing/device/drink/batchUpdate',
        'method': 'POST',
        data
    })
}

export const deviceCtrlDrinkParamUpload = (data) => {
  return request({
    'url': '/api/v1/marketing/device/ctrl/drinkParamUpload',
    'method': 'POST',
    data
  })
}

export const deviceOnline = () => {
  return request({
    'url': '/api/v1/marketing/device/online',
    'method': 'GET',
  })
}

export const deviceDrink = (params) => {
  return request({
    'url': '/api/v1/marketing/device/drink',
    'method': 'GET',
    params
  })
}







