<template>
  <div class="delivery-allocated-users">
    <div class="delivery-allocated-device-data-list">
      <el-table :data="devices"
                ref="multipleTable"
                max-height="420px"
                @select="listChange"
                @select-all="handleBatch">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column label="设备编号" align="center" prop="deviceUuid"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="deviceName"></el-table-column>
        <el-table-column label="联系人" align="center" prop="dutyUserName"></el-table-column>
        <el-table-column label="联系电话" align="center" prop="dutyUserPhone"></el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>

</template>

<script>
  import {deviceUnion, deviceList} from '@/api/equipment/device'

  export default {
    name: '',
    props: {},
    components: {},
    computed: {},
    mounted: function () {
    },
    data: function () {
      return {
        devices: [],
        loading: false,
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
          deviceInfo: ''
        },
        checkDevice: []
      }
    },

    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        deviceList(this.pagination).then(res => {
          this.devices = res.data || []
          this.$nextTick(function () {
            this.judoCheck(this.devices)
          })
        })
      },

      judoCheck(devices) {
        for (let j = 0; j < devices.length; j++) {
          this.$set(devices[j], `checked`, false)
        }

        for (let j = 0; j < devices.length; j++) {
          for (let i = 0; i < this.checkDevice.length; i++) {
            if (devices[j].deviceUuid === this.checkDevice[i].deviceUuid) {
              this.$refs.multipleTable.toggleRowSelection(devices[j])
              break
            }
          }
        }
        return devices
      },

      handleBatch(value) {
        for (let j = 0; j < this.devices.length; j++) {
          for (let i = 0; i < this.checkDevice.length; i++) {
            if (this.devices[j].deviceUuid === this.checkDevice[i].deviceUuid) {
              this.checkDevice.splice(i, 1)
            }
          }
        }

        if (value.length !== 0) {
          for (let j = 0; j < value.length; j++) {
            this.checkDevice.push(value[j])
          }
        }
      },


      listChange(selection) {
        this.handleBatch(selection)
      },


      loadDevice(info = '', devices) {
        let vm = this
        vm.checkDevice = []
        devices = (devices || [])

        this.pagination.deviceInfo = info
        devices.forEach(item => {
          vm.checkDevice.push({
            deviceUuid: item.deviceUuid, deviceName: item.deviceName,
          })
        })

        deviceUnion(this.pagination).then(res => {
          vm.devices = res.data.list || []
          vm.pagination.total = parseInt(res.data.total)
          this.$nextTick(function () {
            vm.judoCheck(vm.devices)
          })
        })
      },


      getCheckDevice() {
        return this.checkDevice
      },
    },
  }
</script>

<style lang="scss">
  .delivery-allocated-users {
    padding: $padding;
    display: flex;
    flex-direction: column;

    .delivery-allocated-device-title {
      .delivery-allocated-device-input-filter {
        input {
          border: none;
          border-bottom: 1px solid;
          border-radius: 0;
        }
      }

      .delivery-allocated-device-status-filter {
        max-width: 124px;

        .el-input--suffix .el-input__inner {
          padding-right: 0;
        }
      }

      .more {
        font-size: .6rem;
        height: 28px !important;
        line-height: 28px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        span {
          cursor: pointer;
        }
      }
    }

    .delivery-allocated-device-data-list {
      margin-top: $padding;
      flex: 1;

      .delivery-allocated-device-status {
        .delivery-allocated-device-info-img {
        }

        .delivery-allocated-device-info-detail {

          p {
            margin-bottom: $padding;
          }

          p:first-child {
            font-size: .8rem;
          }
        }
      }

      .delivery-allocated-device-address {
        p {
          margin-bottom: $padding;
        }
      }

      .el-table {
        thead {
          tr {
            th {
              border-bottom: 1px solid;
            }
          }
        }

      }

      .el-table--group::after, .el-table--border::after, .el-table::before {
        background-color: transparent;
      }

      .el-table__row:hover {
      }

    }

    .pagination {
      margin: $padding;
    }
  }

</style>
