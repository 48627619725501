<template>
  <div class="drink-price">
    <div class="drink-price-edit-item">
      <p class="drink-price-edit-item-title">
        待修改饮品 <span class="add" @click="handleAddDrink" v-show="drinks.length <20"><i class="iconfont icontianjia"></i>添加饮品</span>
        <span>(单次最多添加20个饮品，已添加饮品数:{{drinks.length}})</span>
      </p>
      <div>
        <el-table :data="drinks" height="240" maxHeight="240" border>
          <el-table-column prop="drinkUuid" label="饮品编号" align="center" min-width="120px"></el-table-column>
          <el-table-column label="饮品名称" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" size="mini"/>
            </template>
          </el-table-column>
          <el-table-column label="饮品英文名称" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.engName" size="mini"/>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="小杯价格(元)" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.price" size="mini" :min="0"
                               @change="scope.row.price=materielExtraCostChange(scope.row.price)"/>
            </template>
          </el-table-column>

          <el-table-column prop="price" label="中杯价格(元)" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.middlePrice" size="mini" :min="0"
                               @change="scope.row.middlePrice=materielExtraCostChange(scope.row.middlePrice)"/>
            </template>
          </el-table-column>

          <el-table-column prop="price" label="大杯价格(元)" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.bigPrice" size="mini" :min="0"
                               @change="scope.row.bigPrice=materielExtraCostChange(scope.row.bigPrice)"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80px">
            <template slot-scope="scope">
              <span class="delete" @click="handleDelete(scope.row, scope.$index)"><i class="iconfont iconshanchu "></i></span>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <div class="drink-price-edit-item">
      <p class="drink-price-edit-item-title">
        待修改设备<span class="add" @click="handleAddDevice"><i class="iconfont icontianjia"></i>添加设备</span>
        <span>(如果当前设备不包含饮品，则饮品价格设置不会生效)</span>
      </p>
      <div class="drink-price-edit-item-content drink-height">
        <el-tag
          size="small"
          v-for="tag in devices"
          :key="tag.deviceUuid"
          @close="handleClose(tag)"
          closable>
          {{tag.deviceName}}
        </el-tag>
      </div>
    </div>

    <div>
      <el-button type="primary" size="small" @click="handleCommit">提交</el-button>
    </div>

    <c-dialog :visible.sync="getDrinkVisible" :title="'设备饮品'" :width="'42rem'" :height="'36rem'">
      <el-button slot="title" size="mini" type="primary" @click="handleGetDrink">确定</el-button>
      <drink-list ref="drinkList"></drink-list>
    </c-dialog>

    <c-dialog :visible.sync="getDeviceVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="pay-bind-device-title">
        <p class="pay-bind-device-title-text">设备选取</p>
        <div class="pay-bind-device-title-container">
          <el-button size="mini" type="primary" plain @click="handleGetDevice">确认</el-button>
        </div>

        <div class="pay-bind-device-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="bind.deviceInfo" placeholder="请输入设备名称" @change="handleInputChange" size="mini"/>
        </div>

      </div>

      <drinkDevice ref="devices"></drinkDevice>
    </c-dialog>


  </div>

</template>

<script>
  import CDialog from '@/components/Dialog'
  import DrinkList from '@/components/DrinkGroup'
  import DrinkDevice from '../Drink/drink_device'

  import {deviceDrinkBatchUpdate} from '@/api/marketing/device'

  export default {
    name: '',
    components: {
      CDialog,
      DrinkList,
      DrinkDevice
    },
    mounted: function () {
    },
    data: function () {
      return {
        getDrinkVisible: false,
        getDeviceVisible: false,
        drinks: [],
        bind: {
          deviceInfo: '',
        },
        devices: []
      }
    },
    methods: {
      handleAddDrink() {
        this.getDrinkVisible = true
        let vue = this
        this.$nextTick(function () {
          vue.$refs.drinkList.loadDrink(vue.drinks)
        })
      },

      handleAddDevice() {
        this.getDeviceVisible = true
        let vue = this
        this.$nextTick(function () {
          vue.$refs.devices.loadDevice('', vue.devices)
        })
      },
      handleDelete(data, index) {
        this.drinks.splice(index, 1)
      },
      handleGetDevice() {
        let devices = (this.$refs.devices.getCheckDevice() || [])
        devices.forEach(item => {
          let i = 0;
          for (; i < this.devices.length; i++) {
            if (this.devices[i].deviceUuid === item.deviceUuid) {
              break
            }
          }
          if (i >= this.devices.length) {
            this.devices.push({
              deviceUuid: item.deviceUuid, deviceName: item.deviceName
            })
          }
        })
        this.getDeviceVisible = false
      },
      handleGetDrink() {
        let drinks = (this.$refs.drinkList.getCheckDrink() || [])
        drinks.forEach(item => {
          let i = 0;
          for (; i < this.drinks.length; i++) {
            if (this.drinks[i].drinkUuid === item.drinkUuid) {
              break
            }
          }
          if (i >= this.drinks.length) {
            this.drinks.push(this.transPrice({
              drinkUuid: item.drinkUuid, name: item.name, engName: item.engName,
              price: item.price, middlePrice: item.middlePrice, bigPrice: item.bigPrice
            }))
          }
        })
        this.getDrinkVisible = false
      },
      handleInputChange(val) {
        this.$refs.devices.loadDevices(val, this.devices)
      },
      handleClose(tag) {
        this.devices.splice(this.devices.indexOf(tag), 1);
      },
      materielExtraCostChange(item) {
        // 防止删除为空
        if (!item) {
          item = '0.00'
        }
        // 一些错误金额输入的判断
        if (item.toString().indexOf('.') > 0 && Number(item.toString().split('.')[1].length) < 1) {
          item = item.toString().split('.')[0]
        }
        // 一些错误金额输入的判断
        if (!item || item === '-' || item === '-0') {
          item = '0.00'
        }
        return parseFloat(item).toFixed(2)

      },
      transPrice(data) {
        data.price = data.price / 100
        data.middlePrice = data.middlePrice / 100
        data.bigPrice = data.bigPrice / 100
        return data
      },
      handleCommit() {
        if (this.devices.length === 0) {
          this.$message.warning('请选取设备')
          return
        }

        if (this.drinks.length === 0) {
          this.$message.warning('请选取饮品')
          return
        }



        let obj = {
          deviceUuid: [],
          drinks:[]
        }

        this.devices.forEach(item => {
          obj.deviceUuid.push(item.deviceUuid)
        })

        this.drinks.forEach(item => {
          obj.drinks.push({
            drinkUuid: item.drinkUuid, name: item.name, engName: item.engName,
            price: parseFloat(item.price) * 100, middlePrice: parseFloat(item.middlePrice) * 100,
            bigPrice: parseFloat(item.bigPrice) * 100
          })
        })

        deviceDrinkBatchUpdate(obj).then(res => {
          this.devices = []
          this.drinks = []
          this.$message.success('更新成功')
        })
      }
    },
  }
</script>

<style lang="scss">
  .drink-price {
    .drink-price-edit-content {
      padding: 0 $padding-8 $padding-8 $padding-8;

      .el-input-number__increase, .el-input-number__decrease {
        border-color: #D8DDE3;
      }
    }

    .el-tag + .el-tag {
      margin-left: 10px;
    }

    .drink-price-edit-item {
      margin-bottom: $padding;

      .drink-price-edit-item-title {
        margin-bottom: $padding-8;

        span {
          font-size: 12px;
          display: inline-block;
          padding-left: $padding-8;


          i {
            font-size: 14px;
          }
        }

        .add {
          color: $_theme_color;
          cursor: pointer;
        }
      }

      .drink-price-edit-item-content {
        padding: $padding-8;
        @include set_bg_color($background-color-light-2, $background-color-dark-1);
      }

      .drink-height {
        height: 160px;
        overflow-y: scroll;
      }
    }

    .el-input-number__increase, .el-input-number__decrease {
      border-color: #D8DDE3;
    }

    .delete {
      color: $btn_sec_color;
      cursor: pointer;
    }

  }

  .pay-bind-device-title {
    display: flex;
    padding: $padding 0;
    align-content: center;

    &-text {
      font-weight: 500;
      margin-right: $padding;
    }

    &-container {
      flex: 1;
      text-align: left;
    }

    &-filter {
      //flex: 1;
      display: flex;
      text-align: right;
      align-content: center;
      margin-right: $padding;

      p {
        width: 120px;
        font-size: 12px;
        margin-right: $padding;
        text-align: right;
      }
    }
  }

  body.dark {
    .drink-price {
      .el-input-number__increase, .el-input-number__decrease {
        border-color: #889DB8;
      }
    }
  }


</style>
